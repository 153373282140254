import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import CrumbTitleLayout from "../layouts/crumbtitlelayout"
import CalSearchResults from "../components/calendar/calsearchresults"
import DayPicker from "react-day-picker"

import Container from "../components/container"
//import CalEventSelector from "../components/calendar/caleventselector"
import useImageMap from "../hooks/imagemap"
import FullDiv from "../components/fulldiv"
//import Breadcrumb from "../components/breadcrumb2"
import FlHdDiv from "../components/flhddiv"
import CalEventsSideBar from "../components/calendar/caleventssidebar"
import useBooleanSearch from "../hooks/booleansearch"
import { useImageName } from "../components/calendar/caleventdetails"
import Row from "../components/row"
import MainSideCol from "../components/mainsidecol"
import useSiteMetadata from "../hooks/sitemetadata"
import BlueButton from "../components/buttons/bluebutton"
import dayjs from "dayjs"
import CalEvent2 from "../components/calendar/calevent2"
import { SideContactCard } from "./allfacultytemplate"
import PageLayout from "../layouts/pagelayout"

const EMPTY_QUERY = ""

export const search = (query: any, calEvents: any): any => {
  const ql = query.text.toLowerCase().replace(/\+/g, " ")

  const ret: any = []

  const now = new Date()

  const nowDay = parseInt(now.toLocaleString("default", { day: "numeric" }))
  const nowMonth = parseInt(now.toLocaleString("default", { month: "numeric" }))
  const nowYear = parseInt(now.toLocaleString("default", { year: "numeric" }))

  for (let event of calEvents) {
    if (
      event.date.day < nowDay ||
      event.date.month < nowMonth ||
      event.date.year < nowYear
    ) {
      continue
    }

    if (
      event.frontmatter.title.toLowerCase().includes(ql) ||
      event.excerpt.toLowerCase().includes(ql)
    ) {
      ret.push(event)
    }
  }

  return ret
}

const booleanSearchAnd = (s1: any, s2: any): any => {
  const names: Set<any> = new Set()

  for (let event of s2) {
    names.add(event.frontmatter.title)
  }

  const ret: any = []

  for (let event of s1) {
    if (names.has(event.frontmatter.title)) {
      ret.push(event)
    }
  }

  return ret
}

const booleanSearchOr = (s1: any, s2: any): any => {
  const names: Set<any> = new Set()

  const keys = new Set()
  const eventMap = new Map<number, any>()

  for (let event of s1) {
    const key = `${event.date.year}-${event.date.month}-${event.date.day}-${event.date.start}-${event.frontmatter.title}`

    keys.add(key)

    if (!eventMap.has(event.date.year)) {
      eventMap.set(event.date.year, new Map<number, any>())
    }

    if (!eventMap.get(event.date.year).has(event.date.month)) {
      eventMap
        .get(event.date.year)
        .set(event.date.month, new Map<number, any>())
    }

    if (
      !eventMap.get(event.date.year).get(event.date.month).has(event.date.day)
    ) {
      eventMap
        .get(event.date.year)
        .get(event.date.month)
        .set(event.date.day, new Map<number, any>())
    }

    if (
      !eventMap
        .get(event.date.year)
        .get(event.date.month)
        .get(event.date.day)
        .has(event.date.start)
    ) {
      eventMap
        .get(event.date.year)
        .get(event.date.month)
        .get(event.date.day)
        .set(event.date.start, [])
    }

    eventMap
      .get(event.date.year)
      .get(event.date.month)
      .get(event.date.day)
      .get(event.date.start)
      .push(event)
  }

  for (let event of s2) {
    const key = `${event.date.year}-${event.date.month}-${event.date.day}-${event.date.start}-${event.frontmatter.title}`

    if (keys.has(key)) {
      continue
    }

    if (!eventMap.has(event.date.year)) {
      eventMap.set(event.date.year, new Map<number, any>())
    }

    if (!eventMap.get(event.date.year).has(event.date.month)) {
      eventMap
        .get(event.date.year)
        .set(event.date.month, new Map<number, any>())
    }

    if (
      !eventMap.get(event.date.year).get(event.date.month).has(event.date.day)
    ) {
      eventMap
        .get(event.date.year)
        .get(event.date.month)
        .set(event.date.day, new Map<number, any>())
    }

    if (
      !eventMap
        .get(event.date.year)
        .get(event.date.month)
        .get(event.date.day)
        .has(event.date.start)
    ) {
      eventMap
        .get(event.date.year)
        .get(event.date.month)
        .get(event.date.day)
        .set(event.date.start, [])
    }

    eventMap
      .get(event.date.year)
      .get(event.date.month)
      .get(event.date.day)
      .get(event.date.start)
      .push(event)
  }

  let ret: any = []

  for (let year of Array.from(eventMap.keys()).sort()) {
    for (let month of Array.from(eventMap.get(year).keys()).sort()) {
      for (let day of Array.from(eventMap.get(year).get(month).keys()).sort()) {
        for (let start of Array.from(
          eventMap.get(year).get(month).get(day).keys()
        ).sort()) {
          //console.log(year, month, typeof day, typeof start, eventMap.get(year).get(month).get(day).get(start)[0].frontmatter.title )
          ret = ret.concat(eventMap.get(year).get(month).get(day).get(start))
        }
      }
    }
  }

  return ret
}

type CalEventProps = {
  calEvent: any
  imageMap: any
  allCalEvents: Array<any>
}

const SingleCalEvent: React.FC<CalEventProps> = ({
  calEvent,
  imageMap,
  allCalEvents,
}) => {
  const title = calEvent.frontmatter.title

  const imageName = useImageName(calEvent)

  return (
    <MainSideCol>
      {/* <FullDiv className="md:mr-4">
        <FlatCard>
          <Row isVCentered={false}>
            <div className="w-28 min-w-28 mr-6 mb-8">
              <CalEventDate calEvent={calEvent} />
            </div>
            <FullDiv>
              <h2 className="m-0">{title}</h2>
              <div className="w-full mt-4">
                <h4>
                  <HTMLDiv o={calEvent} />
                </h4>

                <div className="w-full">
                  <CalEventLocation event={calEvent} />
                </div>
              </div>
              <div className="mt-4">
                <GrayLink to={calEvent.icsFile}>Add To Calendar</GrayLink>
              </div>
            </FullDiv>
          </Row>
        </FlatCard>
      </FullDiv> */}

      <CalEvent2 event={calEvent} />
      <div className="ml-16">
        <SideContactCard title="Upcoming Event">
          <CalEventsSideBar events={allCalEvents} />
        </SideContactCard>
      </div>
    </MainSideCol>
  )
}

export const useCalEvents = (data: any) => {
  const ret = []

  const { icalAPI } = useSiteMetadata()

  for (const { node } of data.calEvents.edges) {
    const calEvent = node

    const title = calEvent.frontmatter.title
    const start = dayjs(calEvent.frontmatter.start)
    const end = dayjs(calEvent.frontmatter.end)
    const formattedTitle = title.toLowerCase().replace(/ /g, "-")
    const formattedDate = start.format("YYYY-MM-DD")

    // calEvent.date = {
    //   year: start.getFullYear(),
    //   month: start.getMonth() + 1,
    //   day: start.getDate(),
    //   start: start.getHours(),
    // }

    calEvent.id = `${formattedTitle}-${formattedDate}`
    calEvent.icsFile = `${icalAPI}?id=${calEvent.id}`

    calEvent.start = start
    calEvent.end = end

    ret.push(calEvent)
  }

  return ret
}

type CalEventsTemplateProps = {
  path: string
  pageContext: any
  data: any
}

const CalEventsTemplate: React.FC<CalEventsTemplateProps> = ({
  path,
  pageContext,
  data,
}) => {
  const { title, crumbs, calEvent } = pageContext

  const allCalEvents: Array<any> = useCalEvents(data)

  const [query, setQuery] = useState(EMPTY_QUERY)
  const [filteredCalEvents, setFilteredCalEvents] = useState(allCalEvents)
  const [page, setPage] = useState(1)
  const [recordsPerPage, setRecordsPerPage] = useState(20)
  const [selectedDays, setSelectedDays] = useState([])
  const [filterEventTypes, setFilterEventTypes] = useState(
    pageContext.filterEventTypes
  )

  const imageMap = useImageMap(data)

  useEffect(() => {
    if (query !== "") {
      setFilteredCalEvents(
        useBooleanSearch(
          query,
          allCalEvents,
          search,
          booleanSearchAnd,
          booleanSearchOr
        )
      )
    } else {
      setFilteredCalEvents(allCalEvents)
    }
  }, [query])

  const offset = 0 //(page - 1) * recordsPerPage

  const handleShowMoreClick = (e: any) => {
    setRecordsPerPage(2 * recordsPerPage)
  }

  const handleDayClick = (day: any, { selected }) => {
    // const { selectedDays } = this.state;
    // if (selected) {
    //   const selectedIndex = selectedDays.findIndex(selectedDay =>
    //     DateUtils.isSameDay(selectedDay, day)
    //   );
    //   selectedDays.splice(selectedIndex, 1);
    // } else {
    //   selectedDays.push(day);
    // }
    setQuery("")
    setSelectedDays(selected ? [] : [day])
    setPage(1)
  }

  const handleSearch = (text: string, clicked: boolean) => {
    // update state according to the latest query and results
    setQuery(text)
    setPage(1)
  }

  const handleTypeClick = (eventTypes: any) => {
    setFilterEventTypes(eventTypes)
  }

  const onPageChanged = (data: any) => {
    const { page } = data
    setPage(page)
  }

  let calEvents

  if (query !== EMPTY_QUERY) {
    calEvents = filteredCalEvents
  } else {
    let dayFilteredEvents = []

    if (selectedDays.length > 0) {
      const day = parseInt(
        selectedDays[0].toLocaleString("default", { day: "numeric" })
      )
      const month = parseInt(
        selectedDays[0].toLocaleString("default", { month: "numeric" })
      )
      const year = parseInt(
        selectedDays[0].toLocaleString("default", { year: "numeric" })
      )

      calEvents = allCalEvents.filter((e: any) => {
        return (
          parseInt(e.frontmatter.day) === day &&
          parseInt(e.frontmatter.monthNum) === month &&
          parseInt(e.frontmatter.year) === year
        )
      })
    } else {
      const now = new Date()

      const day = parseInt(now.toLocaleString("default", { day: "numeric" }))
      const month = parseInt(
        now.toLocaleString("default", { month: "numeric" })
      )
      const year = parseInt(now.toLocaleString("default", { year: "numeric" }))

      calEvents = allCalEvents.filter((e: any) => {
        const d = parseInt(e.frontmatter.day)
        const m = parseInt(e.frontmatter.monthNum)
        const y = parseInt(e.frontmatter.year)
        const t1 = d >= day && m === month && y === year
        const t2 = m > month && y >= year
        const t3 = y > year

        return t1 || t2 || t3
      })
    }
  }

  // Filter by types
  if (filterEventTypes.length > 0) {
    calEvents = calEvents.filter((e: any) => {
      for (let t of filterEventTypes) {
        if (e.frontmatter.tagList.includes(t)) {
          return true
        }
      }

      return false
    })
  }

  let pagedEvents = calEvents.slice(offset, offset + recordsPerPage)

  // // Filter by types
  // if (filterEventTypes.length > 0) {
  //   calEvents = calEvents.filter((e: any) => {
  //     for (let t of filterEventTypes) {
  //       if (e.frontmatter.tags.includes(t)) {
  //         return true
  //       }
  //     }

  //     return false
  //   })
  // }

  return (
    <PageLayout
      path={path}
      crumbs={crumbs}
      title={calEvent !== null ? calEvent.frontmatter.title : title}
      nav="Events"
    >
      <FlHdDiv>
        <Container>
          {calEvent !== null && query === "" ? (
            <SingleCalEvent
              calEvent={calEvent}
              allCalEvents={calEvents}
              imageMap={imageMap}
            />
          ) : (
            <MainSideCol>
              <FullDiv>
                {/* <H1>Events</H1> */}

                {/* <ShowSmall size="lg">
            <SearchBar
              onChange={onChange}
              placeholder="Search events..."
              text={query}
            />
          </ShowSmall> */}

                {/* <HideSmall size="lg">
                  <Row isCentered={true}>
                    <div className="w-1/2">
                      <SearchBar
                        onChange={handleInputChange}
                        placeholder="Search events..."
                        text={query}
                      />
                    </div>
                  </Row>
                </HideSmall> */}
                {/* <FlatCard autoHide={false}> */}
                <CalSearchResults
                  events={calEvents}
                  imageMap={imageMap}
                  pagedEvents={pagedEvents}
                  page={page}
                  recordsPerPage={recordsPerPage}
                  onPageChanged={onPageChanged}
                />

                {recordsPerPage < calEvents.length && (
                  <Row isCentered={true} className="mt-8">
                    <div>
                      <BlueButton onClick={handleShowMoreClick}>
                        More Events
                      </BlueButton>
                    </div>
                  </Row>
                )}
                {/* </FlatCard> */}
              </FullDiv>

              <Row className="md:pl-16">
                <DayPicker
                  selectedDays={selectedDays}
                  onDayClick={handleDayClick}
                />
              </Row>
            </MainSideCol>
          )}
        </Container>
      </FlHdDiv>
    </PageLayout>
  )
}

export default CalEventsTemplate

export const query = graphql`
  query {
    calEvents: allMarkdownRemark(
      sort: { fields: frontmatter___start, order: ASC }
      filter: { fileAbsolutePath: { regex: "/events/" } }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            location
            start
            date: start(formatString: "MMM DD, YYYY")
            day: start(formatString: "D")
            weekday: start(formatString: "ddd")
            month: start(formatString: "MMMM")
            monthNum: start(formatString: "M")
            year: start(formatString: "YYYY")
            startTime: start(formatString: "h:mm a")
            end
            endTime: end(formatString: "h:mm a")
            url
            tagList
          }
          excerpt(format: HTML)
        }
      }
    }

    images: allFile(
      filter: {
        absolutePath: { regex: "/images/people/500x500/rounded/" }
        ext: { regex: "/png/" }
      }
    ) {
      edges {
        node {
          name
          ext
          relativePath
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 500, aspectRatio: 1)
          }
        }
      }
    }
  }
`
